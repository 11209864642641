import Vue from 'vue';
import moment from 'moment';
import api from '@/services/api';

const store = {
  namespaced: true,
  state: {
    steps: [],
    stepsAmount: [],
    processes: [],
    process: {},
    isSearchedProcesses: false,
  },
  mutations: {
    UPDATE_PROCESS(state, payload) {
      state.process = payload;
    },
    UPDATE_PROCESSES(state, payload) {
      state.processes = payload;
    },
    UPDATE_STEPS(state, payload) {
      state.steps = payload;
    },
    UPDATE_STEPS_AMOUNT(state, payload) {
      state.stepsAmount = payload;
    },
    UPDATE_IS_SEARCHED_PROCESSES(state, payload) {
      state.isSearchedProcesses = payload;
    },
  },
  actions: {
    async getProcess(context, payload) {
      try {
        context.dispatch('toggleLoading', true, { root: true });
        const response = await api.get(`/api/public/customer/processes/${payload.identification}`);
        context.commit('UPDATE_PROCESS', response.data.data);
        context.dispatch('toggleLoading', false, { root: true });
        return true;
      } catch (err) {
        context.dispatch('toggleLoading', false, { root: true });
        Vue.$toast.error('Não foi possível recuperar o processo!');
        return false;
      }
    },
    async getProcesses(context, payload) {
      context.dispatch('toggleLoading', true, { root: true });
      try {
        let params = 'concluido=false';
        if (payload.stepId) {
          if (payload.stepId === '8' || payload.stepId === 8) {
            params += '&stepsIds=7,2,1,3,4,5,11';
          } else {
            params += `&stepsIds=${payload.stepId}`;
          }
        } else if (payload.field && payload.from && payload.to) {
          const from = `${moment(payload.from, 'DD/MM/YYYY').format('Y-MM-DDT')}00:00:00`;
          const to = `${moment(payload.to, 'DD/MM/YYYY').format('Y-MM-DDT')}23:59:00`;
          params += `&field=${payload.field}&from=${from}&to=${to}`;
        } else {
          context.dispatch('toggleLoading', false, { root: true });
          Vue.$toast.success('Você precisar selecionar um Campo de Data, uma Data Inicial e uma Data Final para gerar o acompanhamento pelo filtro.\n\nSe você deseja apenas ver os processos da etapa, apenas clique na etapa desejada.', {
            position: 'top-center',
            pauseOnHover: true,
            dismissible: true,
            duration: 5000,
            queue: true,
          });
        }

        const response = await api.get(`/customers/import/report/general?${params}`);
        if (response) {
          context.commit('UPDATE_PROCESSES', response.data.data);
          context.dispatch('toggleLoading', false, { root: true });
          Vue.$toast.success(`Foram encontrados ${response.data.data.length} processos de importação`, {
            position: 'top-center',
            pauseOnHover: true,
            dismissible: true,
            duration: 5000,
          });
        }
      } catch (err) {
        context.dispatch('toggleLoading', false, { root: true });
        Vue.$toast.error(`Não foi possível recuperar os processos: ${err}`, {
          position: 'top-center',
          pauseOnHover: true,
          dismissible: true,
          duration: 5000,
        });
      }
    },
    async getSteps(context) {
      context.dispatch('toggleLoading', true, { root: true });
      try {
        const response = await api.get('/processSteps/search/findAllByOrderByPositionAsc');
        if (response) {
          // eslint-disable-next-line no-underscore-dangle
          const steps = [...response.data._embedded.processSteps].sort((a, b) => (a.position && b.position ? a.position.localeCompare(b.position) : -1));
          context.commit('UPDATE_STEPS', steps);
          context.dispatch('toggleLoading', false, { root: true });
        }
      } catch (err) {
        context.dispatch('toggleLoading', false, { root: true });
        Vue.$toast.error('Não foi possível recuperar as etapas de processo!');
      }
    },
    updateSteps(context, payload) {
      context.commit('UPDATE_STEPS', payload);
    },
    updateProcesses(context, payload) {
      context.commit('UPDATE_PROCESSES', payload);
    },
    updateProcess(context, payload) {
      context.commit('UPDATE_PROCESS', payload);
    },
    updateIsSearchProccesses(context, payload) {
      context.commit('UPDATE_IS_SEARCHED_PROCESSES', payload);
    },
    async getProcessDocuments(context, payload) {
      try {
        context.dispatch('toggleLoading', true, { root: true });
        const response = await api.get(`/api/public/customer/processes/${payload}/documents`);
        context.dispatch('toggleLoading', false, { root: true });
        return response.data.data;
      } catch (err) {
        context.dispatch('toggleLoading', false, { root: true });
        Vue.$toast.error('Não foi possível recuperar os documentos do processo!');
        return null;
      }
    },
    async getProcessInvoices(context, payload) {
      try {
        context.dispatch('toggleLoading', true, { root: true });
        const response = await api.get(`/api/public/customer/invoices/by-process-id/${payload}`);
        context.dispatch('toggleLoading', false, { root: true });
        return response.data.data;
      } catch (err) {
        context.dispatch('toggleLoading', false, { root: true });
        Vue.$toast.error('Não foi possível recuperar as invoices do processo!');
        return null;
      }
    },
  },
};

export default store;
