<template>
  <header class="flex ai-c jc-sb">
    <form class="header-search relative" @submit.prevent="submitSearch">
      <Input
        name="search"
        type="search"
        placeholder="Digite o que você deseja pesquisar"
        className="header-search-input no-border"
        v-model="searchValue"
      />
      <button type="submit">
        <SearchIcon />
      </button>
    </form>

    <div class="flex ai-c">
      <UserMenu />
    </div>
    <ModalProcess
      :handler="modalProcessOpen"
      @request-close="
        () => {
          reminderOpen = false;
          modalProcessOpen = false;
        }
      "
      :data="modalData"
    />
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import { SearchIcon } from '@/components/Icons';

import api from '@/services/api';
import Input from '@/components/DataInput/Input.vue';
import UserMenu from '@/components/UserMenu.vue';
import ModalProcess from '@/components/ModalProcess.vue';

const qs = require('qs');

export default {
  name: 'Header',
  components: {
    // Icons
    SearchIcon,
    // Components
    Input,
    UserMenu,
    ModalProcess,
  },
  data() {
    return {
      searchValue: '',
      modalProcessOpen: false,
      modalData: {},
    };
  },
  computed: {
    ...mapState('process', ['processes']),
  },
  methods: {
    ...mapActions('process', [
      'updateProcesses',
      'updateProcess',
      'updateIsSearchProccesses',
      'getProcesses',
      'getProcessDocuments',
      'getProcessInvoices',
    ]),
    ...mapActions(['toggleLoading']),
    async openProcess(process) {
      this.toggleLoading(true);
      this.modalData = process;
      this.modalData.documents = await this.getProcessDocuments(process.id);
      this.modalData.invoices = await this.getProcessInvoices(process.id);
      this.modalProcessOpen = true;
      this.toggleLoading(false);
    },
    async submitSearch() {
      if (this.searchValue !== '') {
        this.toggleLoading(true);

        const route = `/customers/import/search?${qs.stringify({
          concluidos: true,
          q: this.searchValue,
        })}`;
        const response = await api.get(route);
        const { data } = response.data;

        if (data.length > 1) {
          this.updateProcesses(data);
          this.updateIsSearchProccesses(true);
          this.$router
            .push({
              path: '/processos',
            })
            .catch(() => {});
        } else if (data[0]) {
          this.openProcess(data[0]);
        } else if (data.length === 0) {
          this.$toast.warning(
            'Nenhum processo encontrado!',
            {
              position: 'top-center',
            },
          );
        }

        this.toggleLoading(false);
      }
    },
  },
};
</script>

<style scoped>
.header-search {
  max-width: 400px;
  width: 100%;
}

.header-search button {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 3rem;
  text-align: center;
}
</style>
