<template>
  <div>
    <PageTitle :breadcrumbName="breadcrumbName" :pageTitle="pageTitle" class="mb-2"/>

    <div class="card columns gap2 mb-1">
      <div class="span-12">
        <h5 class="mb-1">PROCESSOS DE IMPORTAÇÃO</h5>
        <p class="text-light text-sm">Quantidade de Processos de Importação do Filtro:
          <strong>{{ processes.length }} processos</strong></p>
      </div>

      <div class="span-12 flex ai-c fgap2 fw-w">
        <div>
          <Select v-model="tipoFiltro" :options="tiposFiltro" title="Tipo de Filtro"/>
        </div>
        <div v-if="tipoFiltro === 'porEtapa'" class="span-4">
          <Select v-model="filtroEtapa" :options="generateSteps" :useIcon="true" title="Etapas"
                  @input="changeFiltroEtapa">
            <template #icon>
              <MapBoxIcon/>
            </template>
          </Select>
        </div>
        <div v-if="tipoFiltro === 'porPeriodo'">
          <Select v-model="filtroCampo" :options="listOfDatesFields" :useIcon="true"
                  title="Tipo de Data">
            <template #icon>
              <CalendarIcon/>
            </template>
          </Select>
        </div>
        <div v-if="tipoFiltro === 'porPeriodo'">
          <Select v-model="dataInicio" :useDate="true" :useIcon="true" title="Data Inicial">
            <template #icon>
              <CalendarIcon/>
            </template>
          </Select>
        </div>
        <div v-if="tipoFiltro === 'porPeriodo'">
          <Select v-model="dataFim" :useDate="true" :useIcon="true" title="Data Final">
            <template #icon>
              <CalendarIcon/>
            </template>
          </Select>
        </div>
        <div class="flex ai-c fgap2 fw-w">
          <div>
            <a v-if="canClearFilters()" class="btn solid danger full-width" href="#"
               @click.prevent="clearFilters()">LIMPAR</a>
          </div>
          <div>
            <a class="btn solid primary full-width" href="#"
               @click.prevent="() => tipoFiltro === 'porEtapa' ? submitSearchStep() : submitSearchPeriod()">GERAR</a>
          </div>
          <div>
            <download-excel v-if="processes.length > 0" :data="processes"
                            :fields="fieldsExcel" class="btn solid success btn-exportar"
                            name="relatorio-processos-importacao.xls" style="white-space: nowrap;">
              GERAR EXCEL
            </download-excel>
          </div>
        </div>
      </div>
      <!--
            <div class="span-6 flex fw-w ai-c fgap2">

            </div> -->
    </div>

    <div class="card columns gap2 mb-2">
      <div class="span-12">
        <GoodTable
          :columns="columns[filtroEtapa.value]"
          :pagination="true"
          :rows="processes"
          :search="true"
          className="vgt-table center text-sm text-table text-medium sm-pd auto-width"
          @rowClick="openProcess"
        />
      </div>
    </div>

    <ModalProcess
      :data="modalData"
      :handler="modalProcessOpen"
      @request-close="() => {
        reminderOpen = false
        modalProcessOpen = false
      }"
    />

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import {
  MapBoxIcon,
  CalendarIcon,
} from '@/components/Icons';
import PageTitle from '@/components/PageTitle.vue';
import Select from '@/components/DataInput/Select.vue';
// import Table from '@/components/Table.vue';
import ModalProcess from '@/components/ModalProcess.vue';
import GoodTable from '@/components/GoodTable.vue';

import * as labelsOfImportProcess from '@/utils/DateLabelsUtil';
import tableColumns from '@/utils/ProcessTableColumns';
import ProcessesReportColumnsExcel from '@/utils/ProcessesReportColumnsExcel';

export default {
  name: 'Home',
  components: {
    // Icons
    MapBoxIcon,
    CalendarIcon,
    // Components
    PageTitle,
    Select,
    GoodTable,
    ModalProcess,
  },
  data() {
    return {
      modalProcessOpen: false,
      filtroEtapa: { label: 'Todas', value: 8 },
      filtroCampo: '',
      dataInicio: '',
      dataFim: '',
      tipoFiltro: 'porEtapa',
      tiposFiltro: [
        {
          label: 'Por Etapa',
          value: 'porEtapa',
        },
        {
          label: 'Por Período',
          value: 'porPeriodo',
        },
      ],
      modalData: {},
      labels: {
        id: {
          label: 'ID',
        },
        ...labelsOfImportProcess.labels[0],
      },
      columns: tableColumns,
      fieldsExcel: ProcessesReportColumnsExcel,
    };
  },
  methods: {
    ...mapActions(
      'process',
      [
        'getSteps',
        'getProcesses',
        'getProcess',
        'updateProcesses',
        'updateIsSearchProccesses',
        'getProcessDocuments',
        'getProcessInvoices',
      ],
    ),
    ...mapActions(['toggleLoading']),
    async openProcess(process) {
      const ok = await this.getProcess({ identification: process.id });
      if (ok) {
        this.modalData = this.process;
        this.modalData.documents = await this.getProcessDocuments(process.id);
        this.modalData.invoices = await this.getProcessInvoices(process.id);
        this.modalProcessOpen = true;
      }
    },
    async getProcessByRouteParams() {
      if (this.$route.params.id) {
        await this.getProcess({ identification: this.$route.params.id });
        this.modalData = this.process;
        this.modalData.documents = await this.getProcessDocuments(this.$route.params.id);
        this.modalData.invoices = await this.getProcessInvoices(this.$route.params.id);
        this.modalProcessOpen = true;
      }
    },
    submitSearchStep() {
      this.clearPeriodFilter();
      this.getProcesses({ stepId: this.filtroEtapa.value });
    },
    submitSearchPeriod() {
      this.filtroEtapa.value = 8;
      this.getProcesses({ field: this.filtroCampo, from: this.dataInicio, to: this.dataFim });
    },
    canClearFilters() {
      return this.filtroEtapa !== 7 || (this.filtroCampo !== '' || this.dataInicio !== '' || this.dataFim !== '');
    },
    clearFilters() {
      this.tipoFiltro = 'porEtapa';
      this.filtroEtapa = { label: 'Produção', value: 7 };
      this.clearPeriodFilter();
      this.getProcesses({ stepId: this.filtroEtapa.value });
    },
    clearPeriodFilter() {
      this.filtroCampo = '';
      this.dataInicio = '';
      this.dataFim = '';
    },
    changeFiltroEtapa(value) {
      const val = this.steps.find((step) => step.id === value);
      this.filtroEtapa = { label: val.description, value: val.id };
    },
  },
  computed: {
    ...mapState('process', ['steps', 'processes', 'isSearchedProcesses', 'process']),
    generateSteps() {
      return this.steps.filter((step) => step.id !== 9 && step.id !== 10).map((step) => (
        { label: step.description, value: step.id }
      ));
    },
    listOfDatesFields() {
      const data = new Array(0);
      if (this.labels !== undefined) {
        // eslint-disable-next-line no-restricted-syntax
        for (const prop in this.labels) {
          if (this.labels[prop].type === 'date') {
            data.push(this.labels[prop]);
          }
        }
      }
      return data;
    },
  },
  async created() {
    await this.getSteps();
    // Etapa Produção fixada (tem que pegar a etapa selecionada pelo usuário)
    if (!this.isSearchedProcesses) {
      await this.getProcesses({ stepId: this.filtroEtapa.value });
    }

    this.toggleLoading(false);

    await this.getProcessByRouteParams();
  },
  destroyed() {
    this.updateProcesses([]);
    this.updateIsSearchProccesses(false);
  },
  mounted() {
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    breadcrumbName: {
      type: String,
      default: '',
    },
  },
};
</script>
