<template>
  <div class="card flex fd-c fgap2">
    <div class="flex ai-c jc-sb">
      <div>
        <h4 class="text-uppercase text-medium">
          PAINEL GERAL DE INDICADORES DOS PROCESSOS DE IMPORTAÇÃO
        </h4>
        <p class="text-light">
          Selecione um período ao lado se deseja buscar períodos diferentes
        </p>
      </div>
      <div class="flex ai-c fgap2">
        <div>
          <Select
            v-model="startDateInput"
            :useDate="true"
            :useIcon="true"
            title="Data Inicial"
          >
            <template #icon>
              <CalendarIcon/>
            </template>
          </Select>
        </div>
        <div>
          <Select
            v-model="endDateInput"
            :useDate="true"
            :useIcon="true"
            title="Data Final"
          >
            <template #icon>
              <CalendarIcon/>
            </template>
          </Select>
        </div>
        <div>
          <button class="btn solid primary"
                  @click="filtrarIndicadores(startDateInput, endDateInput)">
            Filtrar
          </button>
        </div>
      </div>
    </div>

    <div
      class="medium-border-radius light-bordered offwhite-bg p-2 columns gap2"
    >
      <div class="span-12">
        <h4 class="text-uppercase text-medium flex ai-c jc-sb">
          <span> TOTAL DE PROCESSOS DE IMPORTAÇÃO </span>
          <span> {{ stepsIndicators.total }} PROCESSOS </span>
        </h4>
        <p class="text-light">Quantidade total de processos por etapas</p>
      </div>

      <StepCard
        :animated="true"
        :processes="stepsIndicators.inProduction"
        :stepNumber="7"
        class="span-2"
        stepName="Produção"
      />
      <StepCard
        :animated="true"
        :processes="stepsIndicators.inBooking"
        :stepNumber="1"
        class="span-2"
        stepName="Booking"
      />
      <StepCard
        :animated="true"
        :processes="stepsIndicators.inTraffic"
        :stepNumber="2"
        class="span-2"
        stepName="Trânsito"
      />
      <StepCard
        :animated="true"
        :processes="stepsIndicators.inClearence"
        :stepNumber="3"
        class="span-2"
        stepName="Desembaraço"
      />
      <StepCard
        :animated="true"
        :processes="stepsIndicators.inLoading"
        :stepNumber="4"
        class="span-2"
        stepName="Carregamento"
      />
      <StepCard
        :animated="true"
        :processes="stepsIndicators.inClosure"
        :stepNumber="5"
        class="span-2"
        stepName="Encerramento"
      />
    </div>

    <div class="columns gap2">
      <div
        class="
          span-4
          medium-border-radius
          light-bordered
          offwhite-bg
          p-2
          flex
          fd-c
          fgap2
        "
      >

        <div>
          <h4 class="text-uppercase text-medium">PROCESSOS ABERTOS</h4>
          <p class="text-light">Período {{ startDateInput }} até {{ endDateInput }}</p>
        </div>

        <div class="flex fd-c fgap1">
          <HorizontalInfoCard
            :animated="true"
            :numero="proceduralMovimentsIndicatorsAtualPeriod.totalBKB"
            descricao="processos"
            sigla="BKBs"
            background="#013884"
          >
            <template #icon>
              <BoxesIcon size="80px"/>
            </template>
          </HorizontalInfoCard>
          <HorizontalInfoCard
            :animated="true"
            :numero="proceduralMovimentsIndicatorsAtualPeriod.totalFcl"
            descricao="processos"
            sigla="FCLs"
          >
            <template #icon>
              <ContainerIcon size="80px"/>
            </template>
          </HorizontalInfoCard>

          <HorizontalInfoCard
            :animated="true"
            :numero="proceduralMovimentsIndicatorsAtualPeriod.totalLcl"
            background="#17335A"
            descricao="processos"
            sigla="LCLs"
          >
            <template #icon>
              <PackageIcon size="80px"/>
            </template>
          </HorizontalInfoCard>

          <HorizontalInfoCard
            :animated="true"
            :numero="proceduralMovimentsIndicatorsAtualPeriod.totalAereo"
            background="#798C9F"
            descricao="processos"
            sigla="Aéreos"
          >
            <template #icon>
              <AirFreightIcon size="80px"/>
            </template>
          </HorizontalInfoCard>

          <HorizontalInfoCard
            :animated="true"
            :numero="proceduralMovimentsIndicatorsAtualPeriod.totalRodoviario"
            background="#818B96"
            descricao="processos"
            sigla="Rodoviários"
          >
            <template #icon>
              <DeliveryTruckIcon size="80px"/>
            </template>
          </HorizontalInfoCard>
        </div>

        <div class="flex ai-fe">
          <h2 class="text-lg text-medium" style="margin-right: 5px">
            {{ proceduralMovimentsIndicatorsAtualPeriod.total }}</h2>
          <p>PROCESSOS</p>
        </div>
      </div>

      <div
        class="
          span-4
          medium-border-radius
          light-bordered
          offwhite-bg
          p-2
          flex
          fd-c
          fgap2
        "
      >
        <div class="flex ai-fs jc-sb fgap2">
          <div>
            <h4 class="text-uppercase text-medium">GRÁFICO DE PERÍODO DE ARMAZENAGEM</h4>
            <p class="text-light">Gráfico de Percentual de Canais</p>
          </div>
          <div class="flex ai-fe">
            <h2 class="text-lg text-medium" style="margin-right: 5px">{{
                timeStorageIndicators.total
              }}</h2>
            <p>Processos</p>
          </div>
        </div>

        <Chartist
          :colors="['#004385', '#17335a', '#798c9f', '#818b96', '#a1a1a1']"
          :series="[
            {
              value: timeStorageIndicators.PRIMEIRO_PERIODO,
              meta: 'Primeiro período',
            },
            {
              value: timeStorageIndicators.SEGUNDO_PERIODO,
              meta: 'Segundo período',
            },
            {
              value: timeStorageIndicators.TERCEIRO_PERIODO,
              meta: 'Terceiro período',
            },
            {
              value: timeStorageIndicators.QUARTO_PERIODO,
              meta: 'Quarto período',
            },
            {
              value: timeStorageIndicators.OUTROS,
              meta: 'Outros',
            }
          ]"
          :showLegends="true"
          chart-name="time"
        />

        <!-- <div class="chart">
          <apexchart
            v-if="parseInt(timeStorageIndicators.total) > 0"
            :options="{
              chart: {
                type: 'donut',
                height: '100%',
                width: '100%'
              },
              colors: ['#004385', '#17335a', '#798c9f', '#818b96', '#a1a1a1'],
              dataLabels: {
                enabled: true
              },
              labels: ['Primeiro período', 'Segundo período', 'Terceiro período', 'Quarto período', 'Outros']
            }"
            :series="[
              timeStorageIndicators.PRIMEIRO_PERIODO,
              timeStorageIndicators.SEGUNDO_PERIODO,
              timeStorageIndicators.TERCEIRO_PERIODO,
              timeStorageIndicators.QUARTO_PERIODO,
              timeStorageIndicators.OUTROS
            ]"
          >
          </apexchart>
          <div class="card" v-else>
            <h5 class="text-nm text-center text-uppercase">Nenhuma informação de Registro de DIs foi encontrada.</h5>
          </div>
        </div> -->
      </div>

      <div
        class="
          span-4
          medium-border-radius
          light-bordered
          offwhite-bg
          p-2
          flex
          fd-c
          fgap2
        "
      >
        <div class="flex ai-fs jc-sb fgap2">
          <div>
            <h4 class="text-uppercase text-medium">REGISTROS DE DECLARAÇõES DE IMPORTAÇÃO</h4>
            <p class="text-light">Percentual de Canais no Período de {{ startDateInput }} até
              {{ endDateInput }}</p>
          </div>
          <div class="flex ai-fe">
            <h2 class="text-lg text-medium" style="margin-right: 5px">
              {{ diRegistrationIndicators.total }}</h2>
            <p>dIs</p>
          </div>
        </div>

        <Chartist
          :colors="['#818b96', '#3B9B28', '#D6B200', '#bd0808']"
          :series="[
            {
              value: diRegistrationIndicators.CINZA,
              meta: 'Canal Cinza',
            },
            {
              value: diRegistrationIndicators.VERDE,
              meta: 'Canal Verde',
            },
            {
              value: diRegistrationIndicators.AMARELO,
              meta: 'Canal Amarelo',
            },
            {
              value: diRegistrationIndicators.VERMELHO,
              meta: 'Canal Vermelho',
            }
          ]"
          :showLegends="true"
          chart-name="regsiter"
        />

        <!-- <div class="chart">
          <apexchart
            v-if="parseInt(diRegistrationIndicators.total) > 0"
            :options="{
              chart: {
                type: 'donut',
                height: '100%',
                width: '100%'
              },
              colors: ['#818b96', '#3B9B28', '#D6B200', '#bd0808'],
              dataLabels: {
                enabled: true
              },
              labels: ['Cinza', 'Verde', 'Amarelo', 'Vermelho']
            }"
            :series="[
              diRegistrationIndicators.CINZA,
              diRegistrationIndicators.VERDE,
              diRegistrationIndicators.AMARELO,
              diRegistrationIndicators.VERMELHO
            ]"
          >
          </apexchart>
          <div class="card" v-else>
            <h5 class="text-nm text-center text-uppercase">Nenhuma informação de Registro de DIs foi encontrada.</h5>
          </div>
        </div> -->
      </div>
    </div>

    <div
      class="medium-border-radius light-bordered offwhite-bg p-2 card-columns gap2"
    >
      <div class="span-10 info-cards-title">
        <h4 class="text-uppercase text-medium">LEAD TIMES DOS PROCESSOS NO PERÍODO</h4>
        <p class="text-light">Média baseada nas datas dos processos</p>
      </div>

      <InfoCard
        :animated="true"
        :bigInfo="leadtimesIndicators.importDeclaration"
        :columns="2"
        description="Dias de diferença entre Presença de Carga X Registro"
        smallInfo="DIAS"
        title="REGISTRO DE DI"
      />

      <!-- <InfoCard
        :columns="2"
        :bigInfo="leadtimesIndicators.release"
        smallInfo="DIAS"
        title="LIBERAÇÃO"
        description="Dias de diferença entre Presença de Carga X Liberação Porto"
        :animated="true"
      /> -->

      <InfoCard
        :animated="true"
        :bigInfo="leadtimesIndicators.loading"
        :columns="2"
        description="Dias de diferença entre Desembaraço X Carregamento"
        smallInfo="DIAS"
        title="CARREGAMENTO"
      />

      <InfoCard
        :animated="true"
        :bigInfo="leadtimesIndicators.delivery"
        :columns="2"
        description="Dias de diferença entre Presença de Carga X Entrega"
        smallInfo="DIAS"
        title="ENTREGA"
      />

      <InfoCard
        :animated="true"
        :bigInfo="leadtimesIndicators.closing"
        :columns="2"
        description="Dias de diferença entre Entrega X Envio do Faturamento"
        smallInfo="DIAS"
        title="ENCERRAMENTO"
      />

      <div v-if="!isHercules" class="info-card-custom flex fd-c ai-c span-2 pointer"
           @click.prevent="indicatorModalOpen = true">
        <div class="top-bar-custom mb-2" style="background-color: #DEE2E6;"></div>
        <div class="text-center mb-4">
          <h4 class="big-info text-md">PERSONALIZE</h4>
          <br/>
          <p class="small-info text-nm"> Clique aqui para criar um indicador para chamar de seu!</p>
          <br/>
          <img src="https://cdn-icons-png.flaticon.com/512/158/158409.png" style="color: #DEE2E6;"
               width="50"/>
        </div>
      </div>

      <InfoCard
        v-else
        :animated="true"
        :bigInfo="herculesIndicator.length"
        :columns="2"
        cursor
        description="Processos com previsão de embarque (ETD) com atraso maior que 7 dias."
        smallInfo="PROCESSOS"
        title="ETD Atrasados"
        @click="openHerculesModal"
      />
    </div>

    <Modal
      :closeButton="true"
      :handler="indicatorModalOpen"
      :maxWidth="30"
      :useActions="true"
      @request-close="closeIndicatorModal"
    >
      <template #head>
        <h2>Personalize seu indicador</h2>
      </template>
      <template #content>
        <p class="mb-2">Descreva na caixa de texto abaixo como você deseja o seu indicador.</p>
        <textarea id="indicator-message" v-model="indicatorMessage" name="indicator-message"
                  placeholder="Descreva a sua necessidade..."></textarea>
      </template>
      <template #cancel-button>
        <button class="btn solid danger" type="button" @click="closeIndicatorModal">Cancelar
        </button>
      </template>
      <template #confirm-button>
        <button class="btn solid primary" type="button" @click="requestIndicator">Solicitar</button>
      </template>
    </Modal>

    <Modal
      :closeButton="true"
      :handler="herculesModalOpen"
      :useActions="true"
      fullWidth
      @request-close="herculesModalOpen = false"
    >
      <template #head>
        <h2>ETD Atrasados</h2>
      </template>
      <template #content>
        <Table :columns="herculesColumns" :data="herculesData" useFilter>
          <template #identification="{ item }">
            <span class="text-bold pointer" @click="openProcess(item)">{{
                item.identification
              }}</span>
          </template>
          <template #initialDatesETD="{ item }">
            <span>{{ item.initialDatesETD | moment('DD/MM/YYYY') }}</span>
          </template>
          <template #datesETD="{ item }">
            <span>{{ item.datesETD | moment('DD/MM/YYYY') }}</span>
          </template>
        </Table>
      </template>
    </Modal>

    <ModalProcess
      :data="modalData"
      :handler="modalProcessOpen"
      @request-close="() => {
        reminderOpen = false
        modalProcessOpen = false
      }"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment/moment';
import {
  CalendarIcon,
  ContainerIcon,
  PackageIcon,
  AirFreightIcon,
  DeliveryTruckIcon,
} from '@/components/Icons';

import Chartist from '@/components/Chartist.vue';
import StepCard from '@/components/StepCard.vue';
import Modal from '@/components/Modal.vue';
import ModalProcess from '@/components/ModalProcess.vue';
import HorizontalInfoCard from '@/components/HorizontalInfoCard.vue';
import InfoCard from '@/components/InfoCard.vue';
import Select from '@/components/DataInput/Select.vue';
import api from '@/services/api';
import isFullMonth from '@/utils/DateUtils';
import BoxesIcon from '@/components/Icons/BoxesIcon.vue';
import Table from './Table.vue';

export default {
  name: 'HomeGeneralTab',
  components: {
    BoxesIcon,
    // Icons
    CalendarIcon,
    ContainerIcon,
    PackageIcon,
    AirFreightIcon,
    DeliveryTruckIcon,
    // Components
    Chartist,
    Modal,
    ModalProcess,
    StepCard,
    HorizontalInfoCard,
    InfoCard,
    Table,
    Select,
  },
  data() {
    return {
      modalData: {},
      reminderOpen: false,
      modalProcessOpen: false,
      startDateInput: '',
      endDateInput: '',
      startDate: '',
      endDate: '',
      variacaoAberturaStartDate: '',
      variacaoAberturaEndDate: '',
      indicatorMessage: '',
      indicatorModalOpen: false,
      isHercules: false,
      herculesIndicator: [],
      herculesModalOpen: false,
      herculesColumns: [
        {
          name: 'PRI',
          key: 'identification',
        },
        {
          name: 'Ref. Pedido/Cliente',
          key: 'customerRef',
        },
        {
          name: 'Status',
          key: 'status',
        },
        {
          name: 'Exportador',
          key: 'exporter.name',
        },
        {
          name: 'Invoice',
          key: 'invoice',
        },
        {
          name: 'Tipo de Embarque',
          key: 'typeOfBoarding',
        },
        {
          name: 'ETD Inicial',
          key: 'initialDatesETD',
        },
        {
          name: 'ETD',
          key: 'datesETD',
        },
      ],
      herculesData: [],
    };
  },
  computed: {
    ...mapState('process', ['stepsAmount']),
    ...mapState('auth', ['user']),
    ...mapState(
      'analytics',
      [
        'leadtimesIndicators',
        'diRegistrationIndicators',
        'stepsIndicators',
        'proceduralMovimentsIndicatorsAtualPeriod',
        'timeStorageIndicators',
      ],
    ),
  },
  methods: {
    ...mapActions(['toggleLoading']),
    ...mapActions('auth', ['updateUser']),
    ...mapActions('process', ['getProcesses', 'getProcessDocuments', 'getProcessInvoices']),
    ...mapActions(
      'analytics',
      [
        'getLeadtimesIndicators',
        'clearAllIndicators',
        'getDiRegistrationIndicators',
        'getStepsIndicators',
        'getProceduralMovimentsIndicatorsAtualPeriod',
        'getTimeStorageIndicators',
      ],
    ),
    async openHerculesModal() {
      this.toggleLoading(true);
      const response = await api.get(`/api/public/customer/analytics/getProcessesByIds?ids=${this.herculesIndicator.join(',')}`);
      this.herculesData = response.data.data;
      this.toggleLoading(false);

      this.herculesModalOpen = true;
    },
    async filtrarIndicadores(start, end) {
      if (this.user.companies !== undefined) {
        this.getIndicadores(this.user, start, end);
      } else {
        const userResponse = await api.get('/credential');
        this.getIndicadores(userResponse.data.data.person, start, end);
      }

      if (this.isHercules) {
        this.getHerculesIndicator(start, end);
      }
    },
    async openProcess(process) {
      this.toggleLoading(true);
      this.modalData = process;
      this.modalData.documents = await this.getProcessDocuments(process.identification);
      this.modalData.invoices = await this.getProcessInvoices(process.id);
      this.toggleLoading(false);
      this.modalProcessOpen = true;
    },
    async getIndicadores(user, start, end) {
      const companiesId = user.companies !== undefined ? user.companies.filter((a) => a.customer).map((a) => a.id).join() : [];

      this.startDate = start !== null ? `${moment(start, 'DD/MM/YYYY').format('Y-MM-DDT')}00:00:00` : `${moment(this.startDate, 'DD/MM/YYYY').format('Y-MM-DDT')}00:00:00`;
      this.endDate = end !== null ? `${moment(end, 'DD/MM/YYYY').format('Y-MM-DDT')}23:59:59` : `${moment(this.endDate, 'DD/MM/YYYY').format('Y-MM-DDT')}23:59:59`;

      const valorStart = start !== null ? start : this.startDate;
      const valorEnd = end !== null ? end : this.endDate;

      // Seta os valores nos inputs de data
      this.startDateInput = valorStart;
      this.endDateInput = valorEnd;

      this.calcularDatasVariacao(valorStart, valorEnd);

      this.toggleLoading(true);
      await this.getStepsIndicators({ companiesId });
      await this.getLeadtimesIndicators({
        startDate: this.startDate,
        endDate: this.endDate,
        companiesId,
      });
      await this.getDiRegistrationIndicators({
        startDate: this.startDate,
        endDate: this.endDate,
        companiesId,
      });
      await this.getProceduralMovimentsIndicatorsAtualPeriod({
        startDate: this.startDate,
        endDate: this.endDate,
        companiesId,
      });
      await this.getTimeStorageIndicators({
        startDate: this.startDate,
        endDate: this.endDate,
        companiesId,
      });
      this.$toast.success('Indicadores gerados com sucesso!');
      this.toggleLoading(false);
    },
    calcularDatasVariacao(startDate, endDate) {
      const startMoment = moment(startDate, 'DD/MM/YYYY');
      const endMoment = moment(endDate, 'DD/MM/YYYY');

      if (isFullMonth(startMoment, endMoment)) {
        this.variacaoAberturaStartDate = startMoment.subtract('months', 1).format('DD/MM/YYYY');
        this.variacaoAberturaEndDate = endMoment.subtract('months', 1).format('DD/MM/YYYY');
      } else {
        const difference = endMoment.diff(startMoment, 'days');

        this.variacaoAberturaEndDate = startMoment.subtract('days', 1).format('DD/MM/YYYY');
        this.variacaoAberturaStartDate = startMoment.subtract('days', difference).format('DD/MM/YYYY');
      }
    },
    async requestIndicator() {
      this.toggleLoading(true);
      const response = await api.post('/api/public/customer/analytics/enviarEmailSolicitacaoNovoIndicador', { message: this.indicatorMessage });

      if (response) {
        this.toggleLoading(false);
        this.indicatorModalOpen = false;
        this.indicatorMessage = '';
        this.$toast.success('Sua solicitação foi enviada com sucesso!');
      }
    },
    closeIndicatorModal() {
      this.indicatorModalOpen = false;
      this.indicatorMessage = '';
    },
    async getHerculesIndicator(startDate, endDate) {
      const firstDate = startDate != null ? `${moment(startDate, 'DD/MM/YYYY').format('Y-MM-DDT')}00:00:00` : this.startDate;
      const secondDate = endDate != null ? `${moment(endDate, 'DD/MM/YYYY').format('Y-MM-DDT')}23:59:59` : this.endDate;

      const herculesIndicatorResponse = await api.get(`/api/public/customer/analytics/getHerculesEtdIndicator?firstDate=${firstDate}&secondDate=${secondDate}`);
      this.herculesIndicator = herculesIndicatorResponse.data.data;
    },
  },
  mounted() {
    // dados que realmente devem ser enviados para o endpoint
    const valueStart = moment().startOf('month').format('DD/MM/YYYY');

    const valueEnd = moment().format('DD/MM/YYYY');

    this.startDate = valueStart;
    this.endDate = valueEnd;

    // Seta os valores nos inputs de data
    this.startDateInput = valueStart;
    this.endDateInput = valueEnd;

    this.calcularDatasVariacao(valueStart, valueEnd);

    this.filtrarIndicadores(this.startDate, this.endDate);

    if (this.isHercules) {
      this.getHerculesIndicator(null, null);
    }
  },
  destroyed() {
    this.clearAllIndicators();
  },
  watch: {
    user: {
      deep: true,
      handler(value) {
        if (value.companies !== undefined && value.companies.length > 0) {
          this.isHercules = value.companies.filter((a) => a.federalID === '07442711000165').length > 0;
        }
      },
    },
  },
};
</script>

<style scoped>
.card-columns {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
}

.chart {
  max-height: 420px;
  overflow: hidden;
}

@media screen and (max-width: 1024px) {
  .card-columns {
    grid-template-columns: 1fr;
  }

  .info-cards-title {
    grid-column: span 1;
  }
}

.info-card-custom {
  border: 1px solid #DEE2E6;
  border-radius: var(--medium-border-radius);
  padding: 0.75rem;
  background-color: var(--white-color);
}

.top-bar-custom {
  width: 100%;
  height: 6px;
}

@media screen and (max-width: 1024px) {
  .info-card-custom {
    grid-column: span 1;
  }
}
</style>
