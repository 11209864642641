import moment from 'moment';

function moneyFormat(value) {
  if (value) {
    return String(value).replace('.', ',');
  }
  return 'N/I';
}

/* eslint-disable quote-props */
const ProcessesReportColumnsExcel = {
  'Ref': 'identification',
  'Etapa': 'step.description',
  'Empresa': 'customer.name',
  'Exportador': 'exporter.name',
  'Ref Pedido': 'customerRef',
  'Outras Referencias': 'anotherRef',
  'Status': 'status',
  'Invoice': 'invoice',
  'Incoterm': 'incoterm',
  'CNTR': 'typeOfBoarding',
  'Abertura': {
    field: 'created',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Entrega Cliente (Prevista)': {
    field: 'datesEstimatedCustomerDeliverDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Prodidão Prevista': {
    field: 'datesEstimatedGoodsReadinesDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'ETD': {
    field: 'datesETD',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'ETA': {
    field: 'datesETA',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Embarque': {
    field: 'datesDepartureDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Chegada': {
    field: 'datesArrivalDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Nº DI': 'diNumber',
  'Registro': {
    field: 'diRegistryDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Valor CIF': {
    field: 'cifValue',
    callback: (value) => `${moneyFormat(value)}`,
  },
};

export const CalendarColumnsExcel = {
  'Ref': 'identification',
  'Etapa': 'step.description',
  'Empresa': 'customerName',
  'Exportador': 'exporterName',
  'Ref Pedido': 'customerRef',
  'Invoice': 'invoice',
  'Incoterm': 'incoterm',
  'Entrega Cliente (Prevista)': {
    field: 'datesEstimatedCustomerDeliverDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Prodidão Prevista': {
    field: 'datesEstimatedGoodsReadinesDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'ETD': {
    field: 'datesETD',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'ETA': {
    field: 'datesETA',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Tipo': {
    field: 'type',
  },
  'Vencimento': {
    field: 'itemDate',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
  'Valor Numerário': {
    field: 'stepCashRequestEstimatedValue',
    callback: (value) => `${moneyFormat(value)}`,
  },
  'Valor Numerário Prev': {
    field: 'estimatedStepCashRequestEstimatedValue',
    callback: (value) => `${moneyFormat(value)}`,
  },
  'Parcela da Invoice (R$)': {
    field: 'installmentInBrl',
    callback: (value) => `${moneyFormat(value)}`,
  },
  'Parcela da Invoice na Moeda': {
    field: 'installment',
    callback: (value) => `${moneyFormat(value)}`,
  },
  'Moeda da Invoice': {
    field: 'currency',
  },
  'Docs enviados p/ câmbio': {
    field: 'documentsSentForExchange',
    callback: (value) => `${value ? moment(value).format('DD/MM/YYYY') : 'N/I'}`,
  },
};

export default ProcessesReportColumnsExcel;
