<template>
  <transition name="modalProcess" mode="out-in">
    <div v-if="handler" class="modal-process">
      <div class="wrapper">
        <div class="modal-header flex ai-c jc-sb fw-w">
          <div class="flex ai-c fgap2">
            <!-- <h2 class="text-primary text-xg"><strong>PRI</strong>{{ data.identification }}</h2> -->
            <h2 class="text-primary text-lg">
              {{
                data.typeOfProcess | typeOfProcess
              }}{{ String(data.identification).padStart(5, '0') }}
            </h2>
            <div class="tag-status"
                 :style="`background-color: ${data.step.color ? data.step.color : 'white'}; color: ${data.step.textColor};`">
              {{ data.step.alias }}
            </div>
            <p class="text-light">STATUS: <span class="text-bg text-bold">"{{ data.status }}"</span>
            </p>
          </div>

          <div @click.prevent="$emit('request-close', true)" class="close-modal">X</div>
        </div>

        <div class="modal-summary flex fw-w ai-c jc-sb fgap2 pv-2 ph-2">
          <div>
            <div class="flex ai-fs mb-1">
              <BuildingIcon class="text-light mr-1"/>
              <div>
                <p class="text-light text-nm">Cliente</p>
                <p class="text-bold text-primary" :class="{ blured: presentationMode }"
                   v-if="data.customer">{{ data.customer.name }}</p>
                <p class="text-thin" v-else>-</p>
              </div>
            </div>
          </div>

          <div>
            <div class="flex ai-fs mb-1">
              <BoxesIcon class="text-light mr-1"/>
              <div>
                <p class="text-light text-nm">Exportador</p>
                <p class="text-bold text-primary" :class="{ blured: presentationMode }"
                   v-if="data.exporter">{{ data.exporter.name }}</p>
                <p class="text-thin" v-else>-</p>
              </div>
            </div>
          </div>

          <div>
            <div class="flex ai-fs mb-1">
              <BoxesIcon class="text-light mr-1"/>
              <div>
                <p class="text-light text-nm">Fabricante</p>
                <p class="text-bold text-primary" :class="{ blured: presentationMode }"
                   v-if="data.manufacturer">
                  {{ data.manufacturer.name }}</p>
                <p class="text-thin" v-else>-</p>
              </div>
            </div>
          </div>

          <div>
            <div class="flex ai-fs mb-1">
              <FileIcon class="text-light mr-1"/>
              <div>
                <p class="text-light text-nm">Ref Pedido / Cliente</p>
                <p class="text-bold text-primary" v-if="data.customerRef">{{ data.customerRef }}</p>
                <p class="text-thin" v-else>-</p>
              </div>
            </div>
          </div>

          <div>
            <div class="flex ai-fs mb-1">
              <InvoiceIcon class="text-light mr-1"/>
              <div>
                <p class="text-light text-nm">Invoice</p>
                <p class="text-bold text-primary" v-if="data.invoice">{{ data.invoice }}</p>
                <p class="text-thin" v-else>-</p>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-tabs">
          <ul class="tabs flex ai-c fgap2">
            <li
              :class="['flex ai-c jc-c', activeTab == 1 ? 'active' : '']"
              @click="activeTab = 1"
            >
              <span>Dados Gerais</span>
            </li>
            <li
              :class="['flex ai-c jc-c', activeTab == 3 ? 'active' : '']"
              @click="activeTab = 3"
            >
              <span>Financeiro</span>
              <span
                class="tag documentos ml-1">{{ this.data?.invoices ? this.data.invoices.length : 0 }}</span>
            </li>
            <li
              :class="['flex ai-c jc-c', activeTab == 4 ? 'active' : '']"
              @click="activeTab = 4"
            >
              <span>Documentos</span>
              <span
                class="tag documentos ml-1">{{ data.documents ? data.documents.length : 0 }}</span>
            </li>
            <li
              :class="['flex ai-c jc-c', activeTab == 5 ? 'active' : '']"
              @click="activeTab = 5"
            >
              <span>Histórico</span>
              <span class="tag importacao ml-1">{{ data.history ? data.history.length : 0 }}</span>
            </li>
          </ul>
          <div class="tab-content">
            <div v-if="activeTab == 1">
              <div class="columns gap2">
                <div class="span-2 flex fd-c fgap2">
                  <h3 class="text-primary text-medium">LINHA DO TEMPO</h3>
                  <UpdatesList :data="updates"/>
                </div>

                <div class="span-7 flex fd-c fgap2">
                  <h3 class="text-primary text-medium">INFORMAÇÕES GERAIS</h3>
                  <div class="columns gap2">
                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Invoice</p>
                      <p class="text-bold text-nm" v-if="data.invoice">{{ data.invoice }}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Data Invoice</p>
                      <p class="text-bold" v-if="data.invoiceDate">
                        {{ data.invoiceDate | moment("DD/MM/YYYY") }}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Valor CIF</p>
                      <p class="text-bold" v-if="data.cifValue">{{ data.cifValue }}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Ref Pedido</p>
                      <p class="text-bold" v-if="data.customerRef">{{ data.customerRef }}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Outras Ref</p>
                      <p class="text-bold" v-if="data.anotherRef">{{ data.anotherRef }}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Local de Embarque</p>
                      <p class="text-bold" v-if="data.placeOfLoading">{{ data.placeOfLoading }}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Despachante</p>
                      <p class="text-bold" v-if="data.customBroker">{{ data.customBroker.name }}</p>
                      <p class="text-thin" v-else>-</p>
                    </div>
                  </div>
                  <div class="columns gap2">
                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Licença</p>
                      <p class="text-bold" v-if="data.importLicense" :class="data.importLicense === true ? 'text-success' : 'text-danger'">{{ data.importLicense ? 'Sim' : 'Não' }}</p>
                      <p class="text-bold text-danger" v-else>Não</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Urgente</p>
                      <p class="text-bold" v-if="data.urgent" :class="data.urgent === true ? 'text-success' : 'text-danger'">{{ data.urgent ? 'Sim' : 'Não' }}</p>
                      <p class="text-bold text-danger" v-else>Não</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">IMO</p>
                      <p class="text-bold" v-if="data.imo" :class="data.imo === true ? 'text-success' : 'text-danger'">{{ data.imo ? 'Sim' : 'Não' }}</p>
                      <p class="text-bold text-danger" v-else>Não</p>
                    </div>
                  </div>

                  <div class="offwhite-bg p-2 medium-border-radius flex fd-c fgap2">
                    <h3 class="text-primary text-medium flex ai-fe fgap2">
                      <span class="mb-1">INFORMAÇÕES LOGÍSTICA</span>
                      <template v-if="data.wayOfTransport">
                        <AirFreightIcon size="50px" v-if="data.wayOfTransport === 'AEREA'"/>
                        <ShipIcon size="50px"  v-else-if="data.wayOfTransport === 'MARITIMA'"/>
                        <TruckMiniIcon size="50px" v-else/>
                      </template>
                    </h3>

                    <div class="columns gap2">
                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Incoterm</p>
                        <p class="text-bold text-primary" v-if="data.incoterm">
                          {{ data.incoterm }}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Via de Transporte</p>
                        <p class="text-bold text-primary" v-if="data.wayOfTransport">
                          {{ data.wayOfTransport }}
                        </p>
                        <p class="text-thin" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Tipo de Embarque</p>
                        <p class="text-bold text-primary" v-if="data.typeOfBoarding">
                          {{ getTipoEmbarque(data) }}
                        </p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Armador</p>
                        <p class="text-bold text-primary" v-if="data.shipowner">
                          {{ data.shipowner.name }}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">BL/AWB/CRT</p>
                        <p class="text-bold text-primary" v-if="data.billOfLading">
                          {{ data.billOfLading }}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Veículo / Navio</p>
                        <p class="text-bold text-primary" v-if="data.vehicle">{{ data.vehicle }}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Navio de Embarque</p>
                        <p class="text-bold text-primary" v-if="data.vehicleTranshipment">
                          {{ data.vehicleTranshipment }}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-3">
                        <p class="text-nm text-light text-normal">Nº CE-Mercante</p>
                        <p class="text-bold text-primary" v-if="data.ceMercante">
                          {{ data.ceMercante }}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-6">
                        <p class="text-nm text-light text-normal">Forwarder</p>
                        <p class="text-bold text-primary" v-if="data.freightForwarder">
                          {{ data.freightForwarder.name }}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-6">
                        <p class="text-nm text-light text-normal">URF de Entrada</p>
                        <p class="text-bold text-primary" v-if="data.destinationPort">
                          {{ data.destinationPort.alias }}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-6">
                        <p class="text-nm text-light text-normal">Recinto Alfandegado</p>
                        <p class="text-bold text-primary" v-if="data.customsEnclosure">
                          {{ data.customsEnclosure.alias }}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-4">
                        <p class="text-nm text-light text-normal">Transportadora</p>
                        <p class="text-bold text-primary" v-if="data.conveyor">
                          {{ data.conveyor.name }}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>

                      <div class="span-4">
                        <p class="text-nm text-light text-normal">Armazém Geral</p>
                        <p class="text-bold text-primary" v-if="data.generalWarehouse">
                          {{ data.generalWarehouse.name }}</p>
                        <p class="text-thin text-primary" v-else>-</p>
                      </div>
                    </div>
                  </div>

                  <h3 class="text-primary text-medium">FATURAMENTO</h3>
                  <div class="columns gap2">
                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Armazenagem</p>
                      <p class="text-bold" v-if="data.storagePeriod">{{ data.storagePeriod }}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Notas Fiscais</p>
                      <p class="text-bold" v-if="data.billingNFsEmissionDate">
                        {{ data.billingNFsEmissionDate | moment("DD/MM/YYYY") }}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Faturamento</p>
                      <p class="text-bold" v-if="data.billingBillCustomerDate">
                        {{ data.billingBillCustomerDate | moment("DD/MM/YYYY") }}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Email</p>
                      <p class="text-bold" v-if="data.billingEmailDate">
                        {{ data.billingEmailDate | moment("DD/MM/YYYY") }}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Envio</p>
                      <p class="text-bold" v-if="data.billingSendDate">
                        {{ data.billingSendDate | moment("DD/MM/YYYY") }}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Encerramento Desp</p>
                      <p class="text-bold" v-if="data.billingCustomBrokerFinishDate">
                        {{ data.billingCustomBrokerFinishDate | moment("DD/MM/YYYY") }}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Empresa</p>
                      <p class="text-bold" v-if="data.billingSendCompanyDescription">
                        {{ data.billingSendCompanyDescription }}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>

                    <div class="span-3">
                      <p class="text-nm text-light text-normal">Tracking / Rastreio</p>
                      <p class="text-bold" v-if="data.billingSendTrackingDescription">
                        {{ data.billingSendTrackingDescription }}</p>
                      <p class="text-thin text-primary" v-else>-</p>
                    </div>
                  </div>
                </div>

                <div class="span-3 flex fd-c fgap2">
                  <div class="primary-bg p-2 medium-border-radius">
                    <h3 class="text-accent text-md text-center mb-2">DECLARAÇÃO DE IMPORTAÇÃO</h3>
                    <div class="columns gap2">
                      <div class="span-6">
                        <p class="text-nm text-white text-normal">Registro</p>
                        <p class="text-bold text-white" v-if="data.diRegistryDate">
                          {{ data.diRegistryDate | moment("DD/MM/YYYY") }}</p>
                        <p class="text-thin text-white" v-else>-</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-white text-normal">Desembaraço</p>
                        <p class="text-bold text-white" v-if="data.diResourcefulnessDate">
                          {{ data.diResourcefulnessDate | moment("DD/MM/YYYY") }}</p>
                        <p class="text-thin text-white" v-else>-</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-white text-normal">Nº Registro</p>
                        <p class="text-bold text-white">{{ data.diNumber }}</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-white text-normal">Protocolo</p>
                        <p class="text-bold text-white" v-if="data.diProtocol">
                          {{ data.diProtocol }} </p>
                        <p class="text-bold text-white" v-else>-</p>
                      </div>
                    </div>
                  </div>

                  <div class="flex ai-c jc-sb p-2 medium-border-radius"
                       style="background-color: #F8F8F8;" v-if="data.diNumber">
                    <div>
                      <DoubleCheckIcon size="3rem"
                                       :class="data.diChannel === 'VERDE' ? 'text-success' : (data.diChannel === 'AMARELO' ? 'text-warning' : (data.diChannel === 'VERMELHO' ? 'text-danger' : (data.diChannel === 'CINZA' ? '' : '')))"/>
                    </div>
                    <div class="flex1 text-center">
                      <h6 class="text-bold text-light">DESEMBARAÇADO</h6>
                      <p
                        :class="data.diChannel === 'VERDE' ? 'text-success' : (data.diChannel === 'AMARELO' ? 'text-warning' : (data.diChannel === 'VERMELHO' ? 'text-danger' : (data.diChannel === 'CINZA' ? '' : '')))">
                        Canal <strong>{{ data.diChannel }}</strong></p>
                    </div>
                  </div>

                  <div class="flex fd-c fgap2">
                    <h3 class="text-primary text-medium">MAPA</h3>
                    <div class="columns gap2">
                      <div class="span-6">
                        <p class="text-nm text-normal">Vistoria</p>
                        <p class="text-bold" v-if="data.datesInspectionMAPADate">
                          {{ data.datesInspectionMAPADate | moment("DD/MM/YYYY") }}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-normal">Liberação</p>
                        <p class="text-bold" v-if="data.datesReleaseMAPADate">
                          {{ data.datesReleaseMAPADate | moment("DD/MM/YYYY") }}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>
                    </div>
                  </div>

                  <div class="flex fd-c fgap2">
                    <h3 class="text-primary text-medium">OUTRAS DATAS</h3>
                    <div class="columns gap2">
                      <div class="span-6">
                        <p class="text-nm text-normal">AWB/BL Liberado</p>
                        <p class="text-bold" v-if="data.datesBillOfLadingReleaseDate">
                          {{ data.datesBillOfLadingReleaseDate | moment("DD/MM/YYYY") }}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-normal">Fat. Recinto</p>
                        <p class="text-bold" v-if="data.datesEnclosureBillingDate">
                          {{ data.datesEnclosureBillingDate | moment("DD/MM/YYYY") }}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-normal">Liberação Porto</p>
                        <p class="text-bold" v-if="data.datesComexDate">
                          {{ data.datesComexDate | moment("DD/MM/YYYY") }}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>
                      <div class="span-6">
                        <p class="text-nm text-normal">Vínculo no Recinto</p>
                        <p class="text-bold" v-if="data.datesLinkInTheEnclosureDate">
                          {{ data.datesLinkInTheEnclosureDate | moment("DD/MM/YYYY") }}</p>
                        <p class="text-thin" v-else>-</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="activeTab == 3">
              <div class="flex fd-c fgap2 mb-3 mt-1">
                <h3 class="text-primary text-medium text-uppercase"> Informações do Numerário </h3>

                <div class="columns gap2">
                   <div class="span-3">
                    <p class="text-nm text-light text-normal">Data Prevista do Numerário</p>
                    <p class="text-bold" v-if="data.estimatedStepCashRequest">{{ this.$moment(data.estimatedStepCashRequest).format('DD/MM/YYYY') }}</p>
                    <p class="text-thin" v-else>-</p>
                  </div>

                  <div class="span-3">
                    <p class="text-nm text-light text-normal">Valor Previsto do Numerário</p>
                    <p class="text-bold" v-if="data.estimatedStepCashRequestEstimatedValue">{{ data.estimatedStepCashRequestEstimatedValue | moneyFilter }}</p>
                    <p class="text-thin" v-else>-</p>
                  </div>

                  <div class="span-3">
                    <p class="text-nm text-light text-normal">Data Confirmada do Numerário</p>
                    <p class="text-bold" v-if="data.stepCashRequest">{{ this.$moment(data.stepCashRequest).format('DD/MM/YYYY') }}</p>
                    <p class="text-thin" v-else>-</p>
                  </div>

                  <div class="span-3">
                    <p class="text-nm text-light text-normal">Valor Confirmado do Numerário</p>
                    <p class="text-bold" v-if="data.stepCashRequestEstimatedValue">{{ data.stepCashRequestEstimatedValue | moneyFilter }}</p>
                    <p class="text-thin text-primary" v-else>-</p>
                  </div>
                </div>
              </div>

              <!-- <hr class="mb-2"/>

              <div class="flex fd-c fgap2 mb-3">
                <h3 class="text-primary text-md text-bold text-uppercase"> Informações de Invoice </h3>
                <div class="columns gap2">
                  <div class="span-3">
                    <p class="text-nm text-light text-normal">Docs Enviados p/ Câmbio</p>
                    <p class="text-bold text-primary" v-if="data.documentsSentForExchange">{{ this.$moment(data.documentsSentForExchange).format('DD/MM/YYYY') }}</p>
                    <p class="text-thin" v-else>-</p>
                  </div>

                  <div class="span-3">
                    <p class="text-nm text-light text-normal">Valor Total Previsto das Invoices</p>
                    <p class="text-bold text-primary" v-if="data.invoiceTotal">{{ data.invoiceTotal | moneyFilter }}</p>
                    <p class="text-thin" v-else>-</p>
                  </div>
                </div>
              </div> -->

              <hr class="mb-3"/>

              <div class="flex ai-c jc-sb fgap2 mb-2">
                <div>
                  <h4 class="text-bold text-uppercase"> Invoices do Processo </h4>
                  <p>Listagem das Invoices vinculadas ao processo.</p>
                </div>
                <div>
                  <div class="flex ai-c fgap1 mb-1">
                    <SearchIcon/>
                    <p>Pesquisar Invoice</p>
                  </div>
                  <div class="search-docs-container relative">
                    <Input
                      name="search"
                      type="search"
                      class="search-docs-input"
                      v-model="invoicesSearch"
                      :addStyle="`height: 35px; padding-right: 120px;`"
                    />
                    <button type="button" class="btn solid primary">PESQUISAR</button>
                  </div>
                </div>

              </div>

              <div>
                <table>
                  <thead>
                    <tr>
                      <th class="text-center-force">Número da Invoice</th>
                      <th class="text-center-force">Data da Invoice</th>
                      <th class="text-center-force">Condição</th>
                      <th class="text-center-force">Moeda</th>
                      <th class="text-center-force">Parcelas</th>
                      <th class="text-right-force">Total</th>
                      <th class="text-right-force">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="invoice in invoicesList" :key="invoice.id" class="pointer" @click.prevent="modalInvoice(invoice)">
                      <td class="text-center-force text-bold">{{ invoice.invoiceNumber }}</td>
                      <td  class="text-center-force">
                        <span v-if="invoice.invoiceDate">
                          {{ invoice.invoiceDate | dateFormat }}
                        </span>
                        <span v-else-if="invoice.estimatedInvoiceDate">
                          {{ invoice.estimatedInvoiceDate | dateFormat  }}
                        </span>
                      </td>
                      <td class="text-center-force">{{ invoice.paymentTerms.replace("Pagamento", "").trim() }}</td>
                      <td class="text-center-force">{{ invoice.currency }}</td>
                      <td class="text-center-force">{{ invoice.installments.length }}</td>
                      <td  class="text-right-force">{{
                            invoice.invoiceAmount
                              ? new Intl.NumberFormat("pt-BR", {
                                  style: "currency",
                                  currency: invoice.currency,
                                }).format(invoice.invoiceAmount)
                              : "S/I"
                          }}
                      </td>
                      <td class="text-right-force">
                        <button class="btn solid primary small only-icon rounded" >
                          <span class="flex">
                            <EyeIcon size="18"/>
                          </span>
                        </button>
                      </td>
                    </tr>
                    <tr v-if="invoicesList.length == 0">
                      <td colspan="7">
                        <div class="flex fd-c ai-c jc-c">
                          <span class="text-bold">{{ textListInvoices }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div v-if="activeTab == 4">
              <div class="flex ai-c jc-sb fgap2">
                <div>
                  <h4 class="text-bold">DOCUMENTOS</h4>
                  <p>Total de {{ data.documents.length }} documentos cadastradas</p>
                </div>

                <div>
                  <div class="flex ai-c fgap1 mb-1">
                    <SearchIcon/>
                    <p>Pesquisar Documentos</p>
                  </div>
                  <div class="search-docs-container relative">
                    <Input
                      name="search"
                      type="search"
                      class="search-docs-input"
                      v-model="documentsSearch"
                      :addStyle="`height: 35px; padding-right: 120px;`"
                    />
                    <button type="button" class="btn solid primary">PESQUISAR</button>
                  </div>
                </div>
              </div>

              <hr class="mb-2"/>

              <div class="columns gap2">
                <div class="span-12">
                  <DocumentsCard
                    type="success"
                    :docs="documentResults"
                    :process="data"
                  />
                </div>
              </div>

            </div>

            <div v-if="activeTab == 5">
              <div class="flex ai-c jc-sb fgap2">
                <div>
                  <h4 class="text-bold">HISTÓRICO</h4>
                  <p>Total de {{ data.history.length }}
                    histórico{{ data.history.length > 1 ? 's' : '' }} cadastradas</p>
                </div>

                <div>
                  <div class="flex ai-c fgap1 mb-1">
                    <SearchIcon/>
                    <p>Pesquisar Histórico</p>
                  </div>
                  <div class="search-docs-container relative">
                    <input
                      type="search"
                      name="historySearch"
                      class="search-docs-input"
                      v-model="historySearch"
                      style="height: 35px; padding-right: 120px;"
                    >
                    <button type="button" class="btn solid primary">PESQUISAR</button>
                  </div>
                </div>
              </div>

              <hr class="mb-2"/>

              <HistoryItem
                :data="{
                  personName: item.createdBy,
                  date: item.created,
                  title: '',
                  description: item.description,
                }"
                v-for="(item, index) in historyResults"
                :key="index"
              />
            </div>
          </div>
        </div>

      </div>

      <Modal
        :backgroundOpacity="0.95"
        :handler="reminderOpen"
        @request-close="reminderOpen = false"
        :useActions="true"
        :maxWidth="40"
      >
        <template #content>
          <h3 class="text-center text-bold mb-2">Lembrete</h3>
          <p class="text-center">{{ data.reminder }}</p>
        </template>

        <template #cancel-button>
          <a href="#" class="btn solid primary" @click.prevent="reminderOpen = false">FECHAR</a>
        </template>
      </Modal>

      <Modal
        :backgroundOpacity="0.95"
        :handler="modalInvoiceOpen"
        @request-close="modalInvoiceOpen = false"
        :width="80"
      >
        <template #content>
          <div class="flex fd-c fgap2 mb-3">
            <h3 class="text-primary text-medium text-uppercase">
              <span>Informações da Invoice</span>
            </h3>
            <div class="columns gap2">
              <div class="span-4">
                <p class="text-nm text-light text-normal">Número da Invoice</p>
                <p class="text-bold" >{{ dataInvoiceModal.invoiceNumber ?? '-'}}</p>
              </div>
              <div class="span-4">
                <p class="text-nm text-light text-normal">Data Prevista da Invoice</p>
                <p class="text-bold">{{ data.estimatedInvoiceDate ? moment(data.estimatedInvoiceDate).format('DD/MM/YYYY') : '-' }}</p>
              </div>
              <div class="span-4">
                <p class="text-nm text-light text-normal">Data Confirmada da Invoice</p>
                <p class="text-bold">{{ dataInvoiceModal.invoiceDate ? moment(dataInvoiceModal.invoiceDate).format('DD/MM/YYYY') : '-' }}</p>
              </div>
              <div class="span-4">
                <p class="text-nm text-light text-normal">Condição</p>
                <p class="text-bold">{{ dataInvoiceModal.paymentTerms ?? '-' }}</p>
              </div>
              <div class="span-4">
                <p class="text-nm text-light text-normal">Moeda</p>
                <p class="text-bold">{{ dataInvoiceModal.currency ?? '-' }}</p>
              </div>
              <div class="span-4">
                <p class="text-nm text-light text-normal">Taxa do Câmbio</p>
                <p class="text-bold">
                  {{
                    dataInvoiceModal?.installments[0]?.lastExchangeRate ? new Intl.NumberFormat('pt-BR', { style: 'decimal', minimumFractionDigits: 4 }).format(dataInvoiceModal.installments[0].lastExchangeRate) : '-'
                  }}
                </p>
              </div>
              <div class="span-4">
                <p class="text-nm text-light text-normal">Exportador</p>
                <p class="text-bold">{{ dataInvoiceModal?.vendor?.name ?? '-' }}</p>
              </div>
              <div class="span-4">
                <p class="text-nm text-light text-normal">Total da Invoice</p>
                <p class="text-bold">
                  {{ dataInvoiceModal.invoiceAmount ? new Intl.NumberFormat("pt-BR", { style: "currency", currency: dataInvoiceModal.currency, }).format(dataInvoiceModal.invoiceAmount) : "-"
                  }}
                </p>
              </div>
            </div>
          </div>

          <hr class="mb-3"/>

          <div class="mb-2">
            <h4 class="text-bold">Parcelas da condição de {{ dataInvoiceModal.paymentTerms ?? "Pagamento Antecipado"}}</h4>
            <p>Listagem de parcelas</p>
          </div>

          <div class="mb-2">
            <table >
              <thead>
                <tr>
                  <th class="text-center-force">Parcela</th>
                  <th class="text-center-force" v-show="dataInvoiceModal.paymentTerms == 'Pagamento à Prazo'">Condição</th>
                  <th class="text-center-force" v-show="dataInvoiceModal.paymentTerms == 'Pagamento à Prazo'">Prazo</th>
                  <th class="text-center-force">Data de Vencimento</th>
                  <th class="text-right-force" style="min-width: 220px; max-width: 220px; width: 220px;">Valor</th>
                  <th class="text-right-force" style="min-width: 220px; max-width: 220px; width: 220px;">Valor em R$</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(installment, index) in dataInvoiceModal.installments" :key="index">
                  <td class="text-center-force">{{ installment.parcel ?? '1' }}</td>
                  <td class="text-center-force" v-show="dataInvoiceModal.paymentTerms == 'Pagamento à Prazo'">{{ installment.condition ?? '-' }}</td>
                  <td class="text-center-force" v-show="dataInvoiceModal.paymentTerms == 'Pagamento à Prazo'">{{ installment.term ?? '-' }}</td>
                  <td class="text-center-force">{{ installment.dueDate ? moment(installment.dueDate ).format('DD/MM/YYYY') : '-' }}</td>
                  <td colspan="2" style="padding: 0;">
                    <table>
                      <tbody>
                        <tr style="padding-bottom: 0.75rem;">
                          <td class="text-right-force td-value">
                            {{ installment.value ? new Intl.NumberFormat("pt-BR", { style: "currency", currency: dataInvoiceModal.currency, }).format(installment.value) : "-" }}
                          </td>
                          <td class="text-right-force td-value">{{ installment.valueInBrl ?? 0 | moneyFilter }}</td>
                        </tr>
                        <tr style="padding-bottom: 0.75rem;">
                          <td colspan="2" class="text-right-force" style="border-bottom: 0; padding: 0 1rem 0.75rem 0;" v-if="installment.lastExchangeRate && installment.lastExchangeUpdate" >
                            <span class="enchange-rate text-sm">
                              Câmbio atualizado em {{ installment.lastExchangeUpdate | dateFormat }} com a taxa
                              {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL', minimumFractionDigits: 4 }).format(installment.lastExchangeRate) }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </template>

        <template #cancel-button>
          <a href="#" class="btn solid primary" @click.prevent="modalInvoiceOpen = false">FECHAR</a>
        </template>
      </Modal>

    </div>
  </transition>
</template>

<script>
import {
  FileIcon,
  BuildingIcon,
  InvoiceIcon,
  BoxesIcon,
  DoubleCheckIcon,
  SearchIcon,
  EyeIcon,
} from '@/components/Icons';

import Input from '@/components/DataInput/Input.vue';
import DocumentsCard from '@/components/DocumentsCard.vue';
import HistoryItem from '@/components/HistoryItem.vue';
import Modal from '@/components/Modal.vue';
import UpdatesList from '@/components/UpdatesList.vue';
import AirFreightIcon from '@/components/Icons/AirFreightIcon.vue';
import ShipIcon from '@/components/Icons/ShipIcon.vue';
import TruckMiniIcon from '@/components/Icons/TruckMiniIcon.vue';
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'ModalProcess',
  components: {
    // Icons
    FileIcon,
    BuildingIcon,
    InvoiceIcon,
    BoxesIcon,
    AirFreightIcon,
    DoubleCheckIcon,
    SearchIcon,
    ShipIcon,
    TruckMiniIcon,
    EyeIcon,
    // Components
    Input,
    DocumentsCard,
    HistoryItem,
    Modal,
    UpdatesList,
  },
  data() {
    return {
      toggleTest: false,
      reminderOpen: false,
      modalInvoiceOpen: false,
      dataInvoiceModal: {},
      activeTab: 1,
      dragging: false,
      file: Object,
      documents: [],
      containerData: {},
      historySearch: '',
      documentsSearch: '',
      invoicesSearch: '',
      textListInvoices: 'Nenhum Invoice foi cadastrada para este processo.',
    };
  },
  filters: {
    moneyFilter(value) {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }).format(value);
    },
    typeOfProcess(value) {
      switch (value) {
        case 'CONTA_E_ORDEM':
          return 'PRI';

        case 'ENCOMENDA':
          return 'E';

        case 'ACESSORIA':
          return 'A';

        default:
          return 'PRI';
      }
    },
    dateFormat(value) {
      return moment(value).format('DD/MM/YYYY');
    },
  },
  methods: {
    moment(date) {
      return moment(date);
    },
    modalInvoice(data) {
      this.dataInvoiceModal = {};
      this.dataInvoiceModal = data;
      this.modalInvoiceOpen = true;
    },
    dragover() {
      this.dragging = true;
    },
    drop(e) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        const file = e.dataTransfer.files[i];
        this.documents.push(file);
      }
      this.dragging = false;
    },
    getNewReference() {
      let ref = 'PRI';

      if (this.data.typeOfProcess === 'ENCOMENDA') {
        ref = 'E';
      }

      if (this.data.typeOfProcess === 'ASSESSORIA') {
        ref = 'A';
      }

      return ref + this.zeroPad(this.data.identification, 5);
    },
    zeroPad(num, places) {
      const zero = places - num.toString().length + 1;

      return Array(+(zero > 0 && zero)).join('0') + num;
    },
    dragleave() {
      this.dragging = false;
    },
    getTipoEmbarque(data) {
      if (data.typeOfBoarding === 'SEM') {
        if (data.wayOfTransport === 'AEREA') {
          return 'Aéreo';
        }

        if (data.wayOfTransport === 'RODOVIARIA') {
          return 'Rodoviário';
        }

        return '-';
      }

      return data.typeOfBoarding;
    },
  },
  mounted() {
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Escape') {
        this.$emit('request-close', true);
      }
    });
  },
  computed: {
    ...mapState(['presentationMode']),
    updates() {
      const itensTimeline = [
        {
          id: 0,
          titulo: 'Abertura',
          descricao: this.$moment(this.data.created).format('DD/MM/YYYY'),
          concluido: true,
          previsao: false,
          opacidade: false,
        },
        {
          id: 1,
          titulo: 'Prontidão Prevista',
          descricao: this.data.datesEstimatedGoodsReadinesDate !== null ? this.$moment(this.data.datesEstimatedGoodsReadinesDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesEstimatedGoodsReadinesDate !== null,
          previsao: true,
          opacidade: this.data.datesGoodsReadinesDate !== null,
        },
        {
          id: 2,
          titulo: 'Prontidão Confirmada',
          descricao: this.data.datesGoodsReadinesDate !== null ? this.$moment(this.data.datesGoodsReadinesDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesGoodsReadinesDate !== null,
          previsao: false,
        },
        {
          id: 3,
          titulo: 'ETD',
          descricao: this.data.datesETD !== null ? this.$moment(this.data.datesETD).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesETD !== null,
          previsao: true,
          opacidade: this.data.datesDepartureDate !== null,
        },
        {
          id: 4,
          titulo: 'Embarque',
          descricao: this.data.datesDepartureDate !== null ? this.$moment(this.data.datesDepartureDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesDepartureDate !== null,
          previsao: false,
        },
        {
          id: 5,
          titulo: 'ETA',
          descricao: this.data.datesETA !== null ? this.$moment(this.data.datesETA).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesETA !== null,
          previsao: true,
          opacidade: this.data.datesArrivalDate !== null,
        },
        {
          id: 6,
          titulo: 'Chegada',
          descricao: this.data.datesArrivalDate !== null ? this.$moment(this.data.datesArrivalDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesArrivalDate !== null,
          previsao: false,
        },
        {
          id: 7,
          titulo: 'Atracação',
          descricao: this.data.mooringDate !== null ? this.$moment(this.data.mooringDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.mooringDate !== null,
          previsao: false,
        },
        {
          id: 8,
          titulo: 'Presença',
          descricao: this.data.datesGoodsPresenceDate !== null ? this.$moment(this.data.datesGoodsPresenceDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesGoodsPresenceDate !== null,
          previsao: false,
        },
        {
          id: 9,
          titulo: 'Registro da DI',
          descricao: this.data.diRegistryDate !== null ? this.$moment(this.data.diRegistryDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.diRegistryDate !== null,
          previsao: false,
        },
        {
          id: 10,
          titulo: 'Desembaraço',
          descricao: this.data.diResourcefulnessDate !== null ? this.$moment(this.data.diResourcefulnessDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.diResourcefulnessDate !== null,
          previsao: false,
        },
        {
          id: 11,
          titulo: 'Carregamento',
          descricao: this.data.datesGoodsLoadingDate !== null ? this.$moment(this.data.datesGoodsLoadingDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesGoodsLoadingDate !== null,
          previsao: false,
        },
        {
          id: 12,
          titulo: 'Entrega Prevista',
          descricao: this.data.datesEstimatedCustomerDeliverDate !== null ? this.$moment(this.data.datesEstimatedCustomerDeliverDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesEstimatedCustomerDeliverDate !== null,
          previsao: true,
          opacidade: this.data.datesCustomerDeliverDate !== null,
        },
        {
          id: 13,
          titulo: 'Entrega Realizada',
          descricao: this.data.datesCustomerDeliverDate !== null ? this.$moment(this.data.datesCustomerDeliverDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.datesCustomerDeliverDate !== null,
          previsao: false,
        },
        {
          id: 14,
          titulo: 'Envio do Faturamento',
          descricao: this.data.billingSendDate !== null ? this.$moment(this.data.billingSendDate).format('DD/MM/YYYY') : '-',
          concluido: this.data.billingSendDate !== null,
          previsao: false,
        },
      ];

      if (this.data.datesDepartureDate !== null && this.data.datesDepartureDate !== 'undefined') {
        const itemEmbarque = itensTimeline.find((item) => item.id === 4);
        const itemETA = itensTimeline.find((item) => item.id === 5);

        itensTimeline[3] = itemEmbarque;
        itensTimeline[4] = itemETA;
      }

      return itensTimeline;
    },
    historyResults() {
      if (this.historySearch) {
        const result = [];

        for (let i = 0; i < Object.keys(this.data.history).length; i += 1) {
          const item = this.data.history[i];
          let returnThis = false;

          for (let y = 0; y < Object.keys(item).length; y += 1) {
            const key = String(Object.values(item)[y]);
            if (new RegExp(this.historySearch, 'gi').test(key)) {
              returnThis = true;
            }
          }

          if (returnThis) {
            result.push(item);
          }
        }

        return result;
      }
      return this.data.history;
    },
    documentResults() {
      if (this.documentsSearch) {
        const result = [];

        for (let i = 0; i < Object.keys(this.data.documents).length; i += 1) {
          const item = this.data.documents[i];
          let returnThis = false;

          for (let y = 0; y < Object.keys(item).length; y += 1) {
            const key = String(Object.values(item)[y]);
            if (new RegExp(this.documentsSearch, 'gi').test(key)) {
              returnThis = true;
            }
          }

          if (returnThis) {
            result.push(item);
          }
        }

        return result;
      }
      return this.data.documents;
    },
    invoicesList() {
      if (this.invoicesSearch) {
        const result = [];
        for (let i = 0; i < Object.keys(this.data.invoices).length; i += 1) {
          const item = this.data.invoices[i];
          let returnThis = false;

          for (let y = 0; y < Object.keys(item).length; y += 1) {
            const key = String(Object.values(item)[y]);
            if (new RegExp(this.invoicesSearch, 'gi').test(key)) {
              returnThis = true;
            }
          }

          if (returnThis) {
            result.push(item);
          }
        }

        if (result.length === 0) {
          this.$set(this, 'textListInvoices', 'Nenhum resultado encontrado.');
        }

        return result;
      }
      return this.data.invoices;
    },
  },
  props: {
    handler: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {
      },
    },
  },
};
</script>

<style scoped>
.modal-process {
  position: fixed;
  inset: 0;
  background-color: var(--white-color);
  padding: 1rem;
  z-index: 999999999;
}

.modal-process .wrapper {
  width: 100%;
  height: 100%;
  background-color: #F2F2F2;
  border: var(--input-border);
  border-radius: var(--medium-border-radius);
  padding: 0.5rem;
  overflow: auto;
}

.tag-status {
  padding: 0.75rem 1.5rem;
  font-weight: 700;
  border-radius: var(--medium-border-radius);
  color: var(--white-color);
}

.tag-obs {
  background-color: var(--white-color);
  padding: 0.5rem;
  border-radius: var(--round-border-radius);
}

.tag-obs .count {
  width: 1.75rem;
  height: 1.75rem;
  border-radius: var(--round-border-radius);
  background-color: var(--warning-color);
  color: var(--white-color);
  margin-left: 5px;
}

.close-modal {
  padding: 0 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  cursor: pointer;
}

.modal-header {
  border-bottom: var(--input-border);
  padding: 0.5rem 0 1rem;
}

.alert {
  border-radius: var(--medium-border-radius);
}

.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: var(--round-border-radius);
  color: var(--white-color);
}

.tag.logistica {
  background-color: var(--accent-color);
}

.tag.importacao {
  background-color: var(--primary-color);
}

.tag.documentos {
  background-color: var(--light-text-color);
}

.modal-tabs .tabs {
  position: relative;
  margin-bottom: -1px;
  overflow: auto;
}

.modal-tabs .tabs li {
  flex: 1 1 auto;
  padding: 0.75rem;
  border-top-left-radius: var(--medium-border-radius);
  border-top-right-radius: var(--medium-border-radius);
  border-left: var(--small-border-width) solid transparent;
  border-right: var(--small-border-width) solid transparent;
  border-top: var(--small-border-width) solid transparent;
  cursor: pointer;
  height: 50px;
}

.modal-tabs .tabs li.active {
  background-color: var(--white-color);
  border-left: var(--input-border);
  border-right: var(--input-border);
  border-top: var(--input-border);
}

.tab-content {
  background-color: var(--white-color);
  padding: 1rem;
  border: var(--input-border);
  border-bottom-left-radius: var(--medium-border-radius);
  border-bottom-right-radius: var(--medium-border-radius);
}

.search-license {
  max-width: 380px;
  position: relative;
}

body .search-license input {
  padding-right: 9rem;
  height: 35px;
}

.tab-content .search-license button {
  height: 35px;
  position: absolute;
  top: 0;
  right: -0;
  bottom: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 30px;
  padding: 0 10px;
}

.step-1 {
  background-color: #F65694;
}

.step-2 {
  background-color: #3B9B28;
}

.step-3 {
  background-color: #D6B200;
}

.step-4 {
  background-color: #3594E9;
}

.step-5 {
  background-color: #A569BD;
}

.step-6 {
  background-color: #D07107;
}

.modalProcess-enter-active,
.modalProcess-leave-active {
  transition: var(--all-transition);
}

.modalProcess-enter,
.modalProcess-leave-to {
  transform: scale(0);
}

.modalProcess-enter-to,
.modalProcess.leave {
  transform: scale(1);
}

.tab-content input, .tab-content select {
  font-weight: bold;
  height: 30px;
}

.search-docs-input {
  min-width: 400px;
}

.search-docs-container button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 35px;
  padding: 0 10px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.upload-placeholder {
  border: var(--medium-border-width) dashed var(--primary-color);
  border-radius: var(--medium-border-radius);
  padding: 1rem;
}

.upload-placeholder.active {
  background-color: rgba(16, 67, 117, 0.7);
}

.modal-process .text-nm {
  font-size: 0.9rem;
}

.text-center-force {
  text-align: center !important;
}

.text-right-force {
  text-align: right !important;
}

table:not(.mx-table, .mx-panel) tr td {
 min-width: auto !important;
}

.enchange-rate {
  padding: 0.25rem;
  margin-bottom: 0;
  background-color: #f5f6f7;
  border-radius: 0.5rem;
  border: 1px solid #e2e2e2;
  color: black;
  margin-bottom: 0.25rem;
  min-width: 384px;
}

.td-value {
  min-width: 220px !important;
  max-width: 220px;
  width: 220px;
  border-bottom: 0 !important;
  padding-right: 1rem;
}

</style>
